.form-text-input {
  display: flex;

  &>header {
    min-width  : 220px;
    display    : flex;
    align-items: center;

    span {
      font-weight: bold;
      color      : $header-text-color;
    }
  }
}

.form-select-box {
  display: flex;

  &>header {
    min-width  : 220px;
    display    : flex;
    align-items: center;

    span {
      font-weight: bold;
      color      : $header-text-color;
    }
  }
}

.form-text-viewer {
  display: flex;

  &>header {
    min-width  : 220px;
    display    : flex;
    align-items: center;

    span {
      font-weight: bold;
      color      : $header-text-color;
    }
  }

  .viewer__box {
    height     : 52px;
    align-items: center;
    display    : flex;
    width      : fit-content;

    span {}
  }
}

.form-check-box {
  display: flex;

  &>header {
    min-width  : 220px;
    display    : flex;
    align-items: center;

    span {
      font-weight: bold;
      color      : $header-text-color;
    }
  }

  .grid__box {
    flex                 : 1;
    display              : grid;
    grid-template-columns: repeat(4, 1fr);
    gap                  : 20px;
  }
}

.form-radio-box {
  display: flex;

  &>header {
    min-width  : 220px;
    display    : flex;
    align-items: center;

    span {
      font-weight: bold;
      color      : $header-text-color;
    }
  }

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiFormGroup-root {
    width                : 100%;
    display              : grid;
    grid-template-columns: repeat(4, 1fr);
    gap                  : 20px;
  }
}

.form-date-picker-box {
  display: flex;

  &>header {
    min-width  : 220px;
    display    : flex;
    align-items: center;

    span {
      font-weight: bold;
      color      : $header-text-color;
    }
  }
}

.form-date-time-picker-box {
  display: flex;

  &>header {
    min-width  : 220px;
    display    : flex;
    align-items: center;

    span {
      font-weight: bold;
      color      : $header-text-color;
    }
  }
}

.form-file-uploader {
  display: flex;

  &>header {
    min-width  : 220px;
    display    : flex;
    align-items: flex-start;

    span {
      font-weight: bold;
      color      : $header-text-color;
    }

    .ico__box {
      margin-left: 10px;
      height     : fit-content;
    }
  }

  .preview__box {
    display       : flex;
    flex-direction: column;
    width         : 100%;
    max-width     : 260px;
    border        : solid 1px $table-border-color;
    border-radius : $base-border-radius;
    overflow      : hidden;
    padding       : $content-padding;
    position      : relative;

    img {
      width: 100%;
    }

    .ico__box {
      position      : absolute;
      display       : flex;
      flex-direction: column;
      align-items   : flex-end;
      right         : 10px;
      top           : 10px;

      svg {
        cursor: pointer;
      }
    }
  }

  .input__box {
    display: none;
  }
}

.form-toast-editor {
  display: flex;
  width  : 100%;

  &>header {
    min-width  : 220px;
    display    : flex;
    align-items: flex-start;

    span {
      font-weight: bold;
      color      : $header-text-color;
    }
  }

  .editor__box {
    display: flex;
    flex   : 1;

    &>div {
      width: 100%;
    }
  }
}



.form-toast-viewer {
  display: flex;
  width  : 100%;

  &>header {
    min-width  : 220px;
    display    : flex;
    align-items: flex-start;

    span {
      font-weight: bold;
      color      : $header-text-color;
    }
  }

  .editor__box {
    display: flex;
    flex   : 1;

    &>div {
      width: 100%;
    }
  }
}


.form-file-multiple-uploader {
  display: flex;

  &>header {
    min-width  : 220px;
    display    : flex;
    align-items: center;

    span {
      font-weight: bold;
      color      : $header-text-color;
    }
  }

  .grid__box {
    flex                 : 1;
    display              : grid;
    grid-template-columns: repeat(4, 1fr);
    gap                  : 20px;

    &.image {
      li {
        min-height: 180px;
      }
    }

    &.file {
      li {
        min-height: 50px;
      }
    }

    li {
      display        : flex;
      justify-content: center;
      align-items    : center;
      width          : 100%;
      border         : solid 1px $form-border-color;
      border-radius  : $form-border-radius;
      position       : relative;
      overflow       : hidden;

      &.add {
        cursor: pointer;

        svg {
          color: $form-border-color;
        }

        &:hover {
          border-color    : $primary-color;
          background-color: rgba(68, 88, 184, 0.04);

          svg {
            color: $primary-color;
          }
        }
      }

      .img__box {
        display   : flex;
        width     : 100%;
        max-width : 260px;
        overflow  : hidden;
        padding   : $content-padding;
        position  : relative;
        box-sizing: border-box;

        img {
          cursor: pointer;
          width : 100%;
        }
      }

      .file__box {
        display   : flex;
        width     : 100%;
        padding   : $content-padding;
        box-sizing: border-box;
        position  : relative;

        span {
          word-break: break-all;
          cursor    : pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .close__box {
        position      : absolute;
        display       : flex;
        flex-direction: column;
        align-items   : flex-end;
        right         : 0px;
        top           : 0px;
        z-index       : 10;

        svg {
          cursor: pointer;
        }
      }
    }
  }

  .input__box {
    display: none;
  }
}

.form-file-multiple-viewer {
  display: flex;

  &>header {
    min-width  : 220px;
    display    : flex;
    align-items: center;

    span {
      font-weight: bold;
      color      : $header-text-color;
    }
  }

  .grid__box {
    flex                 : 1;
    display              : grid;
    grid-template-columns: repeat(4, 1fr);
    gap                  : 20px;

    &.image {
      li {
        min-height: 180px;
      }
    }

    &.file {
      li {
        min-height: 50px;
      }
    }

    li {
      display        : flex;
      justify-content: center;
      align-items    : center;
      width          : 100%;
      border         : solid 1px $form-border-color;
      border-radius  : $form-border-radius;
      position       : relative;
      overflow       : hidden;

      &.add {
        cursor: pointer;

        svg {
          color: $form-border-color;
        }

        &:hover {
          border-color    : $primary-color;
          background-color: rgba(68, 88, 184, 0.04);

          svg {
            color: $primary-color;
          }
        }
      }

      .img__box {
        display   : flex;
        width     : 100%;
        max-width : 260px;
        overflow  : hidden;
        padding   : $content-padding;
        position  : relative;
        box-sizing: border-box;

        img {
          cursor: pointer;
          width : 100%;
        }
      }

      .file__box {
        display   : flex;
        width     : 100%;
        padding   : $content-padding;
        box-sizing: border-box;
        position  : relative;

        span {
          word-break: break-all;
          cursor    : pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      .close__box {
        position      : absolute;
        display       : flex;
        flex-direction: column;
        align-items   : flex-end;
        right         : 0px;
        top           : 0px;
        z-index       : 10;

        svg {
          cursor: pointer;
        }
      }
    }
  }

  .input__box {
    display: none;
  }
}

.form-text-area {
  display: flex;

  &>header {
    min-width  : 220px;
    display    : flex;
    align-items: flex-start;

    span {
      font-weight: bold;
      color      : $header-text-color;
    }
  }

  textarea {
    width        : 100%;
    height       : 360px;
    overflow-y   : auto;
    padding      : 16px;
    border-radius: 16px;

    &:disabled {
      background: transparent;
    }
  }
}

.form-json-viewer {
  display: flex;

  &>header {
    min-width  : 220px;
    display    : flex;
    align-items: center;

    span {
      font-weight: bold;
      color      : $header-text-color;
    }
  }

  .json-viewer__box {
    display: flex;
  }
}