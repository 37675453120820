.main-layout {
  display: flex;
  width: 100%;
  min-height: 100vh;
  position: relative;
  background: $base-bg-color;

  .navigation__container {
    display: flex;
    flex-direction: column;
    width: $navigation-width;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .content__container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: $navigation-width;
    padding: $header-height $page-padding $page-padding $page-padding;
    box-sizing: border-box;
    & > .header__box {
      z-index: 10;
      display: flex;
      height: $header-height;
      position: fixed;
      top: 0;
      right: 0;
      background: #fff;
      width: 100%;
    }

    & > .content__box {
      width: 100%;
      min-width: $content-min-width;
      max-width: $content-max-width;
      margin-top: $page-padding;
      display: flex;
      flex-direction: column;
      padding-bottom: $page-padding;
    }
  }
}
