.search-filter {
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow;
  border-radius: $basic-border-radius;
  box-sizing: border-box;
  overflow: hidden;

  & > header {
    padding: $content-padding;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $content-padding;
    background: $table-header-bg-color;
    border-bottom: solid 1px $table-border-color;
    span {
      font-weight: bold;
    }
  }

  ul {
    padding: $content-padding;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: $page-padding;
    li {
      & > header {
        min-width: 160px;
        display: flex;
        align-items: center;
        // background: red;
        span {
          font-weight: bold;
          color: $header-text-color;
        }
      }

      display: flex;
    }
  }
}
