.ico {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: red;
  cursor: pointer;
  &.delete {
    width: 18px;
    height: 18px;
    background: url('./images/ic_delete@3x.png');
    background-size: 100% 100%;
  }
  &.imageholder {
    width: 100px;
    height: 100px;
    background: url('./images/img_imageholder@3x.png');
    background-size: 100% 100%;
  }
}
