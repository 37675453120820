.user-login-page {
  display       : flex;
  flex-direction: column;
  align-items   : center;
  width         : 480px;
  border        : solid 1px #eee;
  border-radius : $basic-border-radius;
  background    : #fff;
  padding       : $page-padding;
  box-sizing    : border-box;

  &>header {
    margin-bottom: $page-padding;

    h1 {
      color: $primary-color;
    }
  }

  .form__box {
    display       : flex;
    flex-direction: column;
    width         : 100%;

    &>article {
      margin-bottom : $content-padding;
      flex-direction: column;
      align-items   : flex-start;

      &>header {
        margin-bottom: 16px;
      }

      &:last-child {
        margin-bottom: 0px;
      }
    }


  }

  .button__box {
    display       : flex;
    flex-direction: column;
    width         : 100%;
    margin-top    : $page-padding;
  }
}