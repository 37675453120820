span,
textarea,
b,
em {
  font-size: 16px;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 22px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
}

h1,
h2,
h3,
h4,
h5 {
  color: $defualt-text-color;
}
