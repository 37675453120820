.navigation {
  display       : flex;
  flex-direction: column;
  background    : $nav-bg-color;
  height        : 100%;

  &>header {
    flex-direction : column;
    display        : flex;
    height         : $header-height;
    justify-content: center;
    align-items    : center;
    border-bottom  : solid 1px $title-color;

    h3 {
      color : $title-color;
      cursor: pointer;
    }
  }

  &>ul {
    display       : flex;
    flex-direction: column;
    // padding: 0px $content-padding;
    margin-top    : $content-padding;
    padding-left  : $content-padding;

    li {
      padding-left: 16px;
      display     : flex;
      align-items : center;
      cursor      : pointer;
      height      : $navigation-item-height;

      &.accordion-item {
        height      : auto;
        padding-left: 0px;
        height      : auto;
        border-left : none;

        .MuiAccordion-root {
          box-shadow   : none;
          width        : 100%;
          border-radius: 0px;
          background   : transparent;
        }

        .MuiAccordionSummary-root {
          color: $contrast-color;
        }

        .MuiAccordionSummary-root.Mui-expanded {
          min-height: 48px;
        }

        .MuiAccordionSummary-content.Mui-expanded {
          // margin: 0px;
        }

        .MuiAccordionDetails-root {
          padding-left: 26px;
          border-left : solid 6px transparent;

          &.active {
            background               : $nav-active-bg-color;
            border-top-left-radius   : $base-border-radius;
            border-bottom-left-radius: $base-border-radius;
            border-left              : solid 6px $primary-color;

            span {
              color  : $contrast-color;
              opacity: 1;
            }
          }
        }
      }

      span {
        color  : $nav-text-color;
        opacity: 0.6;
      }

      border-left: solid 6px transparent;

      &.active {
        background               : $nav-active-bg-color;
        // border-radius: $base-border-radius;
        border-top-left-radius   : $base-border-radius;
        border-bottom-left-radius: $base-border-radius;
        border-left              : solid 6px $primary-color;

        span {
          color  : $contrast-color;
          opacity: 1;
        }
      }
    }
  }
}