.main-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-width: $header-min-width;
  //   background: $primary-color;
  box-shadow: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
  & > section {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding: 0px 40px;
    align-items: center;
  }
  .option__box {
    span {
      cursor: pointer;
      font-size: 12px;
    }
  }
}
