.basic-modal {
  position     : absolute;
  top          : 50%;
  left         : 50%;
  transform    : translate(-50%, -50%);
  width        : 560px;
  background   : #fff;
  border-radius: 16px;
  overflow     : hidden;

  &.nft {
    width: 800px;

  }

  &.address {
    width: 620px;

    .sol-address {
      .content__box {
        padding   : 5px 10px;
        box-sizing: border-box;
      }
    }
  }

  .header__box {
    display        : flex;
    justify-content: center;
    height         : 56px;
    align-items    : center;
    box-sizing     : border-box;
    border-bottom  : solid 1px #e6e6e6;

    .button__box {
      position: absolute;
      right   : 20px;

    }
  }

  .content__box {
    display       : flex;
    align-items   : center;
    flex-direction: column;
    padding       : 30px 20px;
    box-sizing    : border-box;
    max-height    : 70vh;
    overflow-y    : auto;

    .input-list__box {
      display       : flex;
      flex-direction: column;
      width         : 100%;

      &>article {
        margin-bottom: 24px;

        &:last-child {
          margin-bottom: 0px;
        }
      }

      &>header {
        border-bottom : solid 1px #c4c4c4;
        margin-bottom : 24px;
        padding-bottom: 12px;
        margin-top    : -12px;

      }

      .form-text-input {
        &>header {
          min-width: 160px;
        }
      }

      .form-select-box {
        &>header {
          min-width: 160px;
        }
      }

      .form-text-viewer {
        &>header {
          min-width: 160px;
        }

      }

      .form-image-viewer {
        &>header {
          min-width: 160px;
        }
      }

      .form-media-viewer {
        &>header {
          min-width: 160px;
        }
      }

      .form-text-area {
        &>header {
          min-width: 160px;
        }
      }
    }



    .submit__box {
      margin-top: 30px;
      width     : 100%;
      display   : flex;
      box-sizing: border-box;
    }
  }

}