.custom-table {
  display       : flex;
  flex-direction: column;
  box-shadow    : $box-shadow;
  border-radius : $basic-border-radius;
  overflow      : hidden;

  .MuiTableHead-root {
    background: $table-header-bg-color;

    th {
      font-weight: bold;
    }
  }

  .MuiTableBody-root {
    background: $table-data-bg-color;
  }

  .MuiTableBody-root {
    background: $table-data-bg-color;

    tr {
      cursor: pointer;

      &:hover {
        background: $table-hover-bg-color;
      }
    }
  }
}